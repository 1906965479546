import { mapActions, mapGetters, mapMutations } from 'vuex';
import _ from 'lodash';

//---mixins
import { dynamicHead } from '@/mixins/dynamic-head.js';

//---components
import ProductCard from '@/components/cards/ProductCard/index.vue';
import InstaBlock from '@/components/custom/InstaBlock/index.vue';

export default {
  name: 'SearchCatalog',
  mixins: [dynamicHead],
  components: {
    InstaBlock,
    ProductCard
  },
  data() {
    return {
      querySearch: '',
      instagramPosts: [],
      searchLoading: false,
      searchResultData: { news: [], products: [] }
    };
  },
  async created() {
    this.querySearch = this.$route.query.search;

    await Promise.all([
      this.getHomeInstagramPosts().then((result) => (this.instagramPosts = result)),
      this.fetchResults(),
      this.getMetaData('search').then(() => {
        this._setMetaParams();
      })
    ]);
  },
  computed: {
    ...mapGetters({
      metaData: 'meta/metaData'
    })
  },
  methods: {
    ...mapActions({
      fetchSearchItems: 'search/GET_SEARCH_LIST',
      getHomeInstagramPosts: 'home/GET_HOME_INSTAGRAM_POSTS',
      getMetaData: 'meta/GET_META_DATA'
    }),
    ...mapMutations({
      changeInstagramPopup: 'popups/CHANGE_INSTAGRAM_POPUP'
    }),
    async fetchResults() {
      this.searchLoading = true;
      this.searchResultData = await this.fetchSearchItems(this.querySearch);
      this.searchLoading = false;
    },
    sendRequest: _.debounce(function () {
      if (this.querySearch.length > 2) {
        this.fetchResults();
      }
    }, 500),
    onChangeSearch() {
      this.sendRequest();
    },
    _setMetaParams() {
      let { metaTitle, metaDescription, image } = this.metaData;
      if (metaTitle) this.metaParams.title = `${metaTitle}${this.$t('seo.metaTitleGeneral')}`;
      if (metaDescription) this.metaParams.description = metaDescription;
      if (image) this.metaParams.image = image;
      this.setMeta();
    }
  }
};
